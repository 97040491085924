.switcher {
  right: calc(var(--spacing) / 2 + var(--scrollbar-width, 0px));
  bottom: var(--spacing);
  width: auto;
  box-shadow: var(--card-box-shadow);
  text-align: right;
  border-radius: 2rem;
  margin-bottom: 0;
  padding: .75rem;
  line-height: 1;
  position: fixed;
}

.switcher:after {
  width: 1rem;
  height: 1rem;
  content: "";
  vertical-align: bottom;
  transition: transform var(--transition);
  background: linear-gradient(to right, currentColor 0 50%, #0000 50%);
  border: .15rem solid;
  border-radius: 50%;
  display: inline-block;
}

.switcher i {
  max-width: 0;
  white-space: nowrap;
  padding: 0;
  font-size: .875rem;
  font-style: normal;
  display: inline-block;
  overflow: hidden;
}

.switcher:focus, .switcher:hover {
  max-width: 100%;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
}

.switcher:hover:after {
  transform: rotate(180deg);
}

.switcher:hover i {
  max-width: 100%;
  padding: 0 calc(var(--spacing) / 2) 0 calc(var(--spacing) / 4);
  transition: max-width var(--transition), padding var(--transition);
}

.switcher:focus {
  box-shadow: var(--card-box-shadow), 0 0 0 .2rem var(--secondary-focus);
}

@media (min-width: 576px) {
  .switcher {
    right: calc(var(--spacing)  + var(--scrollbar-width, 0px));
  }
}

/*# sourceMappingURL=index.56a26881.css.map */
